<template>
    <div class="roleTab clearfix">
      <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
      <global-table ref="libraryData" :tableField="tableField" :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"
    ></global-table>
    </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { request } from '@/assets/js/http.js'
import { SearchList, SearchData, tableField, tableData } from './addMaterielOutLibraryBody.js'
export default {
  components: Component.components,
  name: 'AddMaterielOutLibraryBody',
  props: ['tabData', 'dataBox'],
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      tableField: tableField,
      tableData: tableData,
      pageNum: 1,
      paginationData: {
        pageSize: pageSize,
        total: 0
      },
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created () {
    this.myBidData()
  },
  mounted () {

  },
  methods: {
    // 获取添加物料数据列表
    myBidData () {
      const obj = {
        pageNum: this.pageNum,
        pageSize: this.paginationData.pageSize,
        supplierNumName: '',
        materialCode: this.childData.searchData.bh,
        materialDesc: this.childData.searchData.ms,
        materialType: this.childData.searchData.lx
      }
      request('/api/order/PurchasingSystem/queryoutsuppliercomponent', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.rows
          if (this.paginationData.total !== res.data.total) {
            this.paginationData.total = res.data.total
          }
          // this.paginationData = { pageSize: pageSize, total: res.data.total }
        }
      })
    },
    // 查询
    onSearch (data) {
      this.pageNum = 1
      this.myBidData()
    },
    // 清空
    clearSearch (data) {
      this.pageNum = 1
      this.myBidData()
    },
    // 分页
    pageChange (data) {
      this.pageNum = data
      this.myBidData()
    }
  },
  watch: {
  }
}
</script>
<style scoped>
  .tableNav{
    border: none;
  }
  .tableList{
    padding: 0 20px;
  }
  .roleTab .tableNav :deep(.searchList .col){
    min-width: 250px;
  }
  .roleTab .tableNav :deep(.searchList .col .title){
    min-width: 42px;
  }
</style>
